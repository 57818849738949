/**
 * Makes block links  entirely clickable, while allowing
 * for text selection and focus of individual links within.
 * Defaults to linking the block to the first link in the card.
 * You can specify a link to follow by adding [data-main-link]
 * to the desired <a> tag
 */

const blockLinks = document.querySelectorAll(
    '[data-block-link]'
)

document.addEventListener('click', (event) => {
    if (event.target.closest('[data-block-link]')) {
        let elMainLink
        const blockLink = event.target.closest('[data-block-link]')
        const links = blockLink.getElementsByTagName('a')
        const isTextSelected = window.getSelection().toString()

        elMainLink = blockLink.querySelector('[data-main-link]')

        // Prevent double link clicks
        Array.from(links).forEach((elLink) => {
            elLink.addEventListener('click', function (event) {
                event.stopPropagation()
            })
        })

        blockLink.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', event => {
                event.stopPropagation()
            })
        })

        // No main link specified, grab the 1st link in the card
        if (!elMainLink) {
            [elMainLink] = links
        }

        if (!isTextSelected) {
            elMainLink.click()
        }
    }
})
