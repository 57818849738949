// Tracking functions
// -------------------------------
// Everything needed for tracking events via GTM
// utilises the data-tracking attribute on click interactions
// Code Author: Cantilver [studio@cantilever.co]

// Get url Location
function getLocationObject(url) {
    var a = document.createElement('a')

    a.href = url

    return {
        href: a.href,
        host: a.host,
        hostname: a.hostname,
        port: a.port,
        pathname: a.pathname,
        protocol: a.protocol,
        hash: a.hash,
        search: a.search,
    }
}

// Add event listener
function activateTracking() {
    // console.log('test');
    window.addEventListener(
        'click',
        (ev) => {
            var clicked = ev.target.closest(['a', 'button'])

            // If the element has tracking information...
            if (clicked && clicked.dataset.tracking) {
                // console.log('click registered');
                // Buttons are easy. Just log the event and presume that whatever else they are supposed to do is happening.
                if (clicked.nodeName === 'BUTTON') {
                    dataLayer.push(JSON.parse(clicked.dataset.tracking))
                } else if (clicked.nodeName === 'A') {
                    // Don't handle any tracking if the the link points to the current location
                    if (clicked.href === window.location.href) {
                        return
                    }
                    // If link has tracking data, track the click. Otherwise, ignore.
                    let metaKeyDown = ev.metaKey
                    ev.preventDefault() // Delay the click for now.
                    try {
                        handleTracking(clicked, metaKeyDown)
                    } catch (err) {
                        goToLink(clicked)
                    }
                }
            }
        },
        true
    )
}

function handleTracking(link, ev) {
    let eventParams = JSON.parse(link.dataset.tracking),
        successful

    // Add a callback to the params we will push to GTM
    eventParams.eventCallback = () => {
        goToLink(link)
        successful = true
    }

    // Log event to GTM. Once done, go to the link
    dataLayer.push(eventParams)

    // Set backup timeout in case it doesn’t work
    setTimeout(() => {
        if (!successful) {
            goToLink(link, ev)
        }
    }, 500)
}

function goToLink(link, metaKeyDown) {
    let location = getLocationObject(link.href)

    if (link.target || metaKeyDown) {
        window.open(link.href, link.target)
    } else {
        window.location.href = link.href
    }
}

export { activateTracking }
