const tabToggle = document.querySelectorAll('[data-tab-toggle]')

tabToggle.forEach(toggle => {
    toggle.addEventListener('click', () => {
        toggle.parentElement.parentElement.classList.toggle('is-active')

        if (toggle.getAttribute('aria-expanded') === 'false') {
            toggle.setAttribute('aria-expanded', 'true')
        } else {
            toggle.setAttribute('aria-expanded', 'false')
        }
    })
})

document.querySelectorAll('.tabs').forEach((tab) => {
    const tabTitle = tab.querySelectorAll('button.tab')
    const tabContent = document.querySelectorAll('.tab__content')


    let tabName

    tabTitle.forEach((element) => {
        element.addEventListener('click', () => {
            tabTitle.forEach((item) => {
                item.classList.remove('is-active')
                item.setAttribute('aria-expanded', 'false')
            })

            element.classList.add('is-active')
            element.setAttribute('aria-expanded', 'true')

            tabName = element.getAttribute('data-tab-index')
            tabContent.forEach((item) => {
                item.classList.contains(tabName)
                    ? item.classList.add('is-active')
                    : item.classList.remove('is-active')
            })
        })
    })
})
