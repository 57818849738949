const quantityInputs = document.querySelectorAll('[data-js-quantity]')

quantityInputs.forEach(quantityInput => {
    const minus = quantityInput.querySelector('[data-minus]')
    const plus = quantityInput.querySelector('[data-plus]')
    const input = quantityInput.querySelector('input')
    const step = Number(input.getAttribute('step') ?? 1)
    const minimum = Number(input.getAttribute('min') ?? 1)

    plus.addEventListener('click', (event) => {
        event.preventDefault()
        input.value = String(Number(input.value) + (step - (input.value % step)))
        input.dispatchEvent(new Event('change'))
    })

    minus.addEventListener('click', (event) => {
        event.preventDefault()
        input.value = String(Math.max(minimum, Number(input.value) - (input.value % step ? input.value % step : step) ))
        input.dispatchEvent(new Event('change'))
    })
})
