import { disablePageScroll, enablePageScroll } from 'scroll-lock'


const openCartEls = document.querySelectorAll('[data-open-cart]')
const cartOverlay = document.querySelector('.cart-overlay')
let focusFrom

const openCart = () => {
    const cart = document.querySelector('.cart')
    document.body.classList.add('cart-is-open')
    disablePageScroll(cart)
    setTimeout(() => document.querySelector('.cart__close').focus(), 250)
    document.addEventListener('keyup', (event) => {
        if(event.key === 'Escape') {
            closeCart()
        }
    })
    document.addEventListener('focusout', event => {
        if(event.target.matches('[data-cart-checkout]')) {
            event.preventDefault()
            document.querySelector('.cart__close').focus()
        }
    })
}

const closeCart = () => {
    const cart = document.querySelector('.cart')
    document.body.classList.remove('cart-is-open')
    enablePageScroll(cart)
    if (focusFrom) {
        focusFrom.focus()
    }
}

openCartEls.forEach(el => {
    el.addEventListener('click', (event) => {
        event.preventDefault()
        focusFrom = event.target
        openCart()
    })
})

document.addEventListener('click', (event) => {
    if(event.target.matches('.cart__close')) {
        closeCart()
    }
})

document.addEventListener('click', (event) => {
    if (event.target.matches('[data-cart-remove]')) {
        setTimeout(() => document.querySelector('.cart__close').focus(), 750)
    }
})


if (cartOverlay) {
    cartOverlay.addEventListener('click', closeCart)
}

export {openCart, closeCart}
