import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { focusable } from '@shopify/theme-a11y'

const mainNav = document.querySelector('.primary-nav-list')
const mobileNav = document.querySelector('.mobile-nav-overlay')
const mobileNavList = mobileNav.querySelector('.mobile-nav__list')
const navToggle = document.querySelector('.hamburger')

const openMobileNav = () => {
    document.body.classList.add('nav-is-open')

    let mobileNavFocusables
    mobileNavFocusables = focusable(mobileNav)
    const [first] = mobileNavFocusables
    const [last] = mobileNavFocusables.reverse()
    disablePageScroll(mobileNavList)

    first.focus()

    first.addEventListener('focusout', event => {
        if (event.relatedTarget !== mobileNav.querySelector('[data-mobile-search-submit]')) {
            event.preventDefault()
            navToggle.focus()
        }
    })

    last.addEventListener('focusout', event => {
        event.preventDefault()
        event.stopPropagation()
        navToggle.focus()
    })

    document.addEventListener('keyup', event => {
        if(event.key === 'Escape') closeMobileNav()
    })
}

const closeMobileNav = () => {
    document.body.classList.remove('nav-is-open')
    enablePageScroll(mobileNavList)
    navToggle.focus()
}

if (mainNav) {
    const mainNavItems = [...mainNav.querySelectorAll('.primary-nav-item')]
    const [first] = mainNavItems
    const [last] = mainNavItems.reverse()
    const subnavs = document.querySelectorAll('.subnav')

    // Allow left/right arrow navigation of top level nav
    mainNav.addEventListener('keyup', (event) => {
        const currentFocus = document.activeElement;
        if (event.key === 'ArrowRight') {
            if (currentFocus.parentElement.nextElementSibling) {
                currentFocus.parentElement.nextElementSibling.querySelector('a').focus();
                return
            }
            // At the end of the nav, focus the first element
            first.querySelector('a').focus();
        }

        if (event.key === 'ArrowLeft') {
            if (currentFocus.parentElement.previousElementSibling) {
                currentFocus.parentElement.previousElementSibling.querySelector('a').focus();
                return
            }

            // At the end of the nav, focus the first element
            last.querySelector('a').focus();
        }
    })

    // Jump out of subnav if escape is pressed inside
    subnavs.forEach(subnav => {
        subnav.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                subnav.previousElementSibling.focus()
            }
        })
    })
}

//Hamburger Menu
if (navToggle) {
    navToggle.addEventListener('click', () => {
        if (document.body.classList.contains('nav-is-open')) {
            closeMobileNav()
            return
        }
        openMobileNav()
    })

    navToggle.addEventListener('blur', event => {
        if (document.body.classList.contains('nav-is-open')) {
            event.preventDefault()
            document.querySelector('.mobile-nav input[type="search"]').focus()
        }
    })
}

// Mobile Nav
const subNavToggles = mobileNav.querySelectorAll('.has-subnav')

subNavToggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
        toggle.classList.toggle('is-open')

        if (toggle.getAttribute('aria-expanded') === 'false') {
            toggle.setAttribute('aria-expanded', 'true')
        } else {
            toggle.setAttribute('aria-expanded', 'false')
        }
    })
})
