const notificationBar = document.getElementById('notification-bar');

const urlParams = new URLSearchParams(window.location.search);
const notification = urlParams.get('notification');

if (notification === 'newsletter-success' && notificationBar) {
    notificationBar.classList.add('success');
    notificationBar.innerHTML = '<p>You have successfully subscribed to our newsletter!</p>';
}

if (notification === 'newsletter-error' && notificationBar) {
    notificationBar.classList.add('error');
    notificationBar.innerHTML = '<p>There was an error in subscribing to the newsletter. Please try again.</p>';
}

if (!notification && notificationBar) {
    notificationBar.classList.add('hidden');
}