import { trapFocus } from '@shopify/theme-a11y'

const modalBlocker = document.querySelector('.modal-blocker')

let focusFrom;

const openModal = (modalName) => {
    const targetModal = document.querySelector(`[data-modal="${modalName}"]`)
    document.body.classList.add('modal-is-open')

    if (targetModal) {
        targetModal.dataset.open = 'true'
        trapFocus(targetModal)
        setTimeout(() => targetModal.querySelector('[data-close-modal]').focus(), 100)
        document.addEventListener('keyup', event => {
            if (event.key === 'Escape') closeModals()
        })
    }
}

const closeModals = () => {
    document.querySelectorAll('[data-modal]').forEach(modal => {
        delete modal.dataset.open
        document.body.classList.remove('modal-is-open')
    })
    if (focusFrom) {
        setTimeout(() => focusFrom.focus(), 300)
    }
}

if(modalBlocker) {
    modalBlocker.addEventListener('click', closeModals)
}

document.querySelectorAll('[data-open-modal]').forEach(toggle => {
    toggle.addEventListener('click', function(event) {
        focusFrom = event.target
        openModal(this.dataset.openModal)
    })
})

document.querySelectorAll('[data-close-modal]').forEach(toggle => {
    toggle.addEventListener('click', closeModals)
})


export { openModal, closeModals }
